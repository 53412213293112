@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-Black.eot');
    src: url('../font/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-Black.woff2') format('woff2'),
        url('../font/SourceSansPro-Black.woff') format('woff'),
        url('../font/SourceSansPro-Black.ttf') format('truetype'),
        url('../font/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-ExtraLight.eot');
    src: url('../font/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-ExtraLight.woff2') format('woff2'),
        url('../font/SourceSansPro-ExtraLight.woff') format('woff'),
        url('../font/SourceSansPro-ExtraLight.ttf') format('truetype'),
        url('../font/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-SemiBold.eot');
    src: url('../font/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('../font/SourceSansPro-SemiBold.woff') format('woff'),
        url('../font/SourceSansPro-SemiBold.ttf') format('truetype'),
        url('../font/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-Regular.eot');
    src: url('../font/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-Regular.woff2') format('woff2'),
        url('../font/SourceSansPro-Regular.woff') format('woff'),
        url('../font/SourceSansPro-Regular.ttf') format('truetype'),
        url('../font/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-Light.eot');
    src: url('../font/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-Light.woff2') format('woff2'),
        url('../font/SourceSansPro-Light.woff') format('woff'),
        url('../font/SourceSansPro-Light.ttf') format('truetype'),
        url('../font/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-Bold.eot');
    src: url('../font/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-Bold.woff2') format('woff2'),
        url('../font/SourceSansPro-Bold.woff') format('woff'),
        url('../font/SourceSansPro-Bold.ttf') format('truetype'),
        url('../font/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0; font-family: inherit; font-size: 100%; font-style: inherit; font-weight: inherit;
    margin: 0; outline: 0; padding: 0; vertical-align: baseline;
}
body{ 
	background: #fff; font-size: 16px; line-height: 1.3; font-family: 'Source Sans Pro'; font-weight: normal; 
	font-style: normal; color:#43425D; overflow-x: hidden; word-wrap: break-word; 
	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
}
:focus {
    outline: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.clear,
.clearfix {
    clear: both;
}
.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-weight: 700;
}
.clear{ clear:  both;}
a{ color: #42425c; transition: all 400ms; -webkit-transition: all 400ms; cursor: pointer; outline: none; text-decoration: none;}
a:hover{ color: #f9951d; outline: none; text-decoration: none;}
a:focus, a:active{color: inherit; outline: none; text-decoration: none;}
img {max-width: 100%; height: auto; vertical-align: top;}
p{ margin: 0 0 15px;}  
ol, ul {list-style: none; margin: 0; padding: 0px;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    margin: 0px 0px 25px 0px; padding: 0px; font-weight: 600; line-height: 1.2; color: #42425c;
}
.center {text-align: center;}  
/*--- Btn--- */
input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button,
.btn {
	min-width: 160px;
	height: 48px;
    color: #fff;
    display: inline-block;
    border-radius: 0px;
    margin: 0px 0px 15px;
    padding: 16px 25px;
    cursor: pointer;
    background: #43425D;
    border: 0px;
    font-size: 18px;    
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
	transition: all 400ms; -webkit-transition: all 400ms;
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus{outline: none;}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover,
button:hover,
.btn:hover {
    color: #fff;
    background: #43425D;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select,
.form-control {	
    padding: 12px 20px;
    border: 1px solid #43425D;
    width: 100%;
    margin: 0 0 15px;
    background: #fff;
    border-radius: 0px;
    height: 48px;
    font-size: 16px;
    color: #43425D;
    font-family: 'Source Sans Pro';
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
	border-radius: 10px;
}
textarea{ resize: none; outline: 0;}
/*--- table--- */
table {width: 100%; border-collapse: collapse; max-width: 100%; margin-bottom: 20px;}
table tr:nth-of-type(even) {background: #e2efdb; }
table th {background: #70ad46; color: #fff; text-align:center;}
table td, table th {padding: 10px; border: 1px solid #70ad46;}
/* ---  Header --- */
.header {
    position: fixed;
    width: 100%;
    top: 0;
	right: 0;
    left: 0;
    height: 55px;
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 10;
	background: #fff;
	-webkit-transition: .3s ease all;
    transition: .3s ease all;
	border-bottom: 1px solid #b7b7b7;
}
.navbar-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;	
    align-items: center;
}
.logo-box {
    height: 55px;
    width: 250px;
	background: #42425c;		
    position: relative;
    padding: 25px 10px 10px;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;	
    border-bottom: 1px solid #42425c;
}
.logo-box a{font-size:18px; font-weight:bold; color:#fff;}
.header-cnt {
    display: flex;
    height: 55px;
    width: calc(100% - 250px);
	align-items: center;
	padding: 0px 30px 0px 10px;
	justify-content: space-between;	
    transition: .3s ease all;
}
.logo-box .logo-sm {
    display:none;
    transition: 0.3s;
}
.logo-box .logo-lg{
    display:block;
    transition: 0.3s;
}
.logo-box.active {
    width: 75px;
}	
.logo-box.active + .header-cnt {width: calc(100% - 75px);}
.logo-box.active .logo-sm {
    display:block;
}
.logo-box.active .logo-lg {
    display:none;
}	
.header .menu-icon {
    display: inline-block;
    width: 22px;
}
.header .menu-icon span {
    background-color: #42425c;
    display: block;
    height: 2px;
    margin-bottom: 4px;
    transition: all .5s;
}
.header .menu-icon span:last-child{margin-bottom:0px;}

.menu-icon.active .icon_menu1 {
	-webkit-transform: translateY(14px) rotate(45deg) scale(1); transform: translateY(8px) rotate(45deg) scale(1); 
}
.menu-icon.active .icon_menu2 {
	-webkit-transform: translate(0) rotate(0deg) scale(0); transform: translate(0) rotate(0deg) scale(0); 
}
.menu-icon.active .icon_menu3 {
	-webkit-transform: translateY(-8px) rotate(-45deg) scale(1); transform: translateY(-4px) rotate(-45deg) scale(1); 
}
.header .r-icon{font-size: 20px; position:relative; color: #42425a; margin-right: 30px;}
.header .r-icon:last-child{ margin-right:0px;} 
.header .bedge {
    background: #f3a00a; display: inline-block; width: 20px; height: 20px;
    border-radius: 100px; font-size: 14px; position: absolute; top: -7px;
    left: 10px; text-align: center; line-height: 20px;
}
.header .notity-icon.r-icon .bedge {background: #FF7588;}
.header-cnt-left {display: flex; align-items: center;}
.search-box {margin: 0px 10px 0px 20px; position: relative;}
.search-box .form-control {
    padding: 12px 22px 12px 44px; border: 1px solid #b0b0b0; width: 100%; margin: 0 0 0px; background: #fff;
    height: 44px; font-size: 16px; color: #42425c; border-radius: 10px;
}
.search-icon {
    display: inline-block; position: absolute; top: 1px; background: #fff; left: 1px; border-radius: 10px;
    padding: 9px 9px 3px;
}
.myaccount {margin-top: 8px; position: relative;}
ul.user-menu {
    top: 55px; right: 20px; width: 125px; height: auto; padding: 15px; background: #FFFFFF;
    box-shadow: 3px 3px 3px #00000029; border: 1px solid #707070; border-radius: 15px;
    opacity: 0; visibility: hidden; position: absolute;
}
ul.user-menu.active {opacity: 1; visibility: visible;}
ul.user-menu li {padding: 8px 0px;}
ul.user-menu li a{position: relative; padding-left: 35px;}
ul.user-menu .icon {position: absolute; left: 0px; top: -2px;}
.icon{background-size:contain; background-repeat: no-repeat !important; height:30px; width:30px; display: inline-block;}
.header-cnt .icon{height:25px; width:25px;}
.sc-icon { background: url(../images/Search.svg); background-size:contain;}
.ck-icon { background: url(../images/Alarm.svg); background-size:contain;}
.a-icon { background: url(../images/Alert.svg); background-size:contain;}
.u-icon { background: url(../images/User.svg); background-size:contain;}
.profile-icon { background: url(../images/Profile.svg); background-size:contain;}
.color-icon { background: url(../images/Color.svg); background-size:contain;}
.logout-icon { background: url(../images/Logout.svg); background-size:contain;}


.dashboard-icon{ background: url(../images/Dashboard.svg); background-size:contain;}
.file-icon{ background: url(../images/Files.svg); background-size:contain;}
.docs-icon{ background: url(../images/Documents.svg); background-size:contain;}
.letter-icon{ background: url(../images/Letters.svg); background-size:contain;}
.comm-icon{ background: url(../images/Communications.svg); background-size:contain;}
.ac-icon{ background: url(../images/Accounts.svg); background-size:contain;}
.user-icon{ background: url(../images/user-icon.svg); background-size:contain;}

.user-icon fill{  color: #fff;}
.setting-icon{ background: url(../images/Settings.svg); background-size:contain;}
.hospital-icon{ background: url(../images/hospital.svg); background-size:contain;}
.insurance-icon{ background: url(../images/insurance.svg); background-size:contain;}


/* ---- Sidebar Menu ---- */
.sidebar-menu {
    color: #404E67;
    background: #42425c;
    position: fixed;
    top: 55px;
    height: calc(100% - 55px);
    width: 250px;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 1px 0 20px rgba(0,0,0,.1);
    z-index: 1;
}
.sidebar-menu .main-menu {padding: 30px 0px;}
.menulist {
	list-style: none; 
	padding: 0; 
	margin: 0;
}
.menulist > li {
    margin: 5px 0px;
	margin-left: -20%;
    position: relative;
    animation: slidein 500ms forwards;
}
.menulist > li > a {
    /*padding: 18px 15px 18px 60px;*/
	padding: 15px 15px 15px 55px;
    position: relative;
    display: block;
    color: #fff;
    white-space: nowrap;
	/*font-size: 18px;*/
    font-size: 17px;
}
.menulist > li > a > i{height:26px; width:26px; position: absolute; left: 15px; top: -2px; bottom: 0; margin: auto 0;}
.menulist > li > a:hover, .menulist > li.active > a {
    background: #15143e; border-left: 8px solid #FFF;
}
.sidebar-menu.menu-close .menulist > li > a {
    padding: 18px 15px 18px 50px;
}
.sidebar-menu.menu-close .menulist > li > a:hover, .sidebar-menu.menu-close .menulist > li.active > a {
    border-left: 6px solid #FFF;
}
.sidebar-menu .submenu.dropdown-menu {
    position: absolute; top: 0; left: 100%; z-index: 1000; display: none; float: left;
    min-width: 150px; width: auto; padding: 10px 0px; margin: -1px; text-align: left;
    list-style: none; background-color: #15143e; background-clip: padding-box;
    border: 1px solid transparent; border-radius: 0px 15px 15px 0px;
}
.sidebar-menu .submenu.dropdown-menu.setting-dropdown{min-width: 220px;}
.sidebar-menu .submenu a {
	color: #fff; 
    display: block;
    padding: 10px 15px;
}
.sidebar-menu.menu-close .submenu {
	position: absolute !important;
    border: 4px;
    left: 30px !important;
    top: 0 !important;
    transform: none !important;
	border-radius: 4px;
}
.sidebar-menu.menu-close .submenu:before {
    content: "";
    border: 10px solid #f8931e;
    border-color: transparent #f8931e transparent transparent;
    position: absolute;
    left: -20px;
    top: 7px;
}
.sidebar-menu .submenu a:hover {
    background: #f0edea; color: #000;
}
.menu-close.sidebar-menu {width: 75px;}
.menu-close .dropdown-toggle::after {
    display: none;
}
.menu-close.sidebar-menu  + .content{
    margin-left: 75px;
}
.menulist > li > a > span {
    display: inline-block;
    opacity: 1;
    transform: translateX(0px);    
    visibility: visible;
}

.menu-close.sidebar-menu .menulist li a span {    
    visibility: hidden;
	opacity: 0;	
	transform: translateX(60px);
}


	@-webkit-keyframes slidein {
		from {
			margin-left: -10%;
		}

		to {
			margin-left: 0%;
		}
	}

	@-moz-keyframes slidein {
		from {
			margin-left: -10%;
		}

		to {
			margin-left: 0%;
		}
	}
	 @-o-keyframes slidein {
		from {
			margin-left: -10%;
		}

		to {
			margin-left: 0%;
		}
	}
	 @keyframes slidein {
		from {
			margin-left: -10%;
		}

		to {
			margin-left: 0%;
		}
	}
	
.menulist > li:nth-child(1){animation-delay: 0.40s; }
.menulist > li:nth-child(2){animation-delay: 0.48s; }
.menulist > li:nth-child(3){animation-delay: 0.56s; }
.menulist > li:nth-child(4){animation-delay: 0.64s; }
.menulist > li:nth-child(5){animation-delay: 0.72s; }
.menulist > li:nth-child(6){animation-delay: 0.80s; }
.menulist > li:nth-child(7){animation-delay: 0.88s; }


/* ---- Content Area ---- */ 

.content {
    padding: 0; position: relative; -webkit-transition: .3s ease all; transition: .3s ease all;
    -webkit-backface-visibility: hidden; backface-visibility: hidden; margin-left: 250px; 
	overflow-x: hidden; margin-top: 55px;
}
.form-wrapper {padding: 70px 90px;}
.form-row {display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 60px;}
.form-left, .form-right {width: 44%;}
.form-group {margin: 0 0 10px;}
.form-group label {margin-bottom: 15px; display: inline-block;}
.form-group textarea.form-control {height: 100px; resize: none; }
.title{padding-bottom: 15px; text-align: center; position: relative;}
.title h4{ font-size:22px; font-weight: bold;}
.btn-group.export {text-align: center;}
.action {display: flex;}
.action .btn {
    min-width: auto; height: auto; margin: 0px 0px 15px; padding: 12px 25px; background: transparent; font-size: 0;
}
.action .edit-btn {
    background: url(../images/edit.svg);
    background-size: contain;
    background-repeat: no-repeat !important;
    height: 16px;
    width: 16px;
    display: inline-block;
	margin: 0px 10px 0px 0px;
    padding: 0px;
}
.action .delete-btn{
    background: url(../images/delete.svg);
    background-size: contain;
    background-repeat: no-repeat !important;
    height: 16px;
    width: 16px;
    display: inline-block;
	margin: 0px;
    padding: 0px;
}

.action .download-btn{
    background: url(../images/download.svg);
    background-size: contain;
    background-repeat: no-repeat !important;
    height: 16px;
    width: 16px;
    display: inline-block;
    margin: 0px;
    margin: 0px 0px 0px 10px;
    padding: 0px;
}
.add-btn{
	position: absolute;
    right: 15px;
    top: 3px;
    background: url(../images/add.svg);
    background-size: contain;
    background-repeat: no-repeat !important;
    height: 24px;
    width: 24px;
    display: inline-block;
	margin: 0px;
    padding: 0px;
	font-size: 0px;
}
.progessBar{
    height: 1rem;
    width: 0%;
    background-color: rgb(68, 212, 231);
    color: white;
    padding:2px
  }
.show-file.btn {
    min-width: 160px;
    height: 38px;
    padding: 10px 15px;
    font-size: 18px;
    text-transform: capitalize;
	margin: 0px 0px 0px;
} 
.refresh-btn {
    margin-right: 20px;
    margin-top: 5px;
}
.refresh-btn .refresh-icon{ background: url(../images/refresh.svg); background-size:contain;}
.addmore-row {
    text-align: center;
    margin-bottom: 40px;
}
.addmore-btn{
    background: url(../images/plus.svg);    
    background-size: contain;
    background-repeat: no-repeat !important;
    height: 55px;
    width: 55px;
    display: inline-block;
	font-size:0;
}

.btn-group.next-back-group {
    text-align: center;
}
.back, .next{
    padding: 10px 10px 10px;
    border: 1px solid #42425cd4;
    display: inline-block;
    margin-right: 10px;
    height: 45px;
    width: 45px;
    border-radius: 5px;
	}
.arrow{background: url(../images/arrow.png); background-size: contain; height: 22px; width: 22px;}
.next {transform: rotate(180deg);}
.form-group .react-datepicker-wrapper {display: block;}
.other-damage-wrap .form-row {margin-bottom: 25px;}
h4.sub-title {
    border-bottom: 1px solid #42425c4f; padding-bottom: 15px; text-transform: uppercase;
    font-size: 18px; margin-bottom: 30px;
} 
.form-group {position: relative;}
.checkbox-right {display: flex; position: absolute; right: 0; top: 0px;}
.checkbox-right input[type="checkbox"] {margin: 5px;}
.doller-inputbox {position: relative;}
.doller-inputbox .doller {position: absolute; top: 12px; left: 10px; font-size: 20px;}
.doller-inputbox input[type="text"], .doller-inputbox .form-control {padding: 12px 15px 12px 30px;}

.form-group-row {display: flex; align-items: center; }
.form-group-row .form-group-left {width: 55%; text-align: right;}
.form-group-row .form-group-left label {margin-right:40px;}
.form-group-row .form-group-right {width: 45%;}
.uplod-documents-wrapper table th {
    background: #ffffff;
    border: 1px solid #ffffff;
    text-align: left;
    font-weight: 600;
    font-size: 18px;
	color: #43425D;
}
.uplod-documents-wrapper table td, .uplod-documents-wrapper table th {border: 1px solid #ffffff;}
.uplod-documents-wrapper table tr:nth-of-type(even) {background: #ffffff;}
.letters-wrap {padding: 30px 30px;}
.letters-row {display: flex; flex-wrap: wrap;}
.letters-left {width: 28%;}
.letters-left-inner {padding: 100px 0px 50px;}
.letters-right {width: 69%; border: 1px solid #707070; border: 1px solid #707070; margin-left: 3%;}
.letters-left .btn{min-width:330px;}
.legal-law-inner { padding: 30px 20px;}
.legal-law-header {display: flex; justify-content: space-between;}
.header-left {background: #808080; width: 64%;}
.header-left h3{font-size: 62px; color: #fff; font-style: italic; padding: 18px 10px; margin: 0;}
.header-right {
    width: 33%;
	border-bottom: 10px solid #808080;
	border-top: 10px solid #808080;
	padding:10px 40px;
}
.header-right p {
    margin: 0 0 0px;
    font-size: 22px;
	color:#808080;
}
.legal-law-inner h4{font-size: 26px; color: #808080; font-weight: 500; margin:0 0 8px;}
.letter-text-wrap {padding: 0px 20px;}
.letter-text-wrap p{margin:0 0 8px;}


.login-form {
    width: 420px; height: 380px; padding: 25px 35px 30px; border: 1px solid #707070; position: absolute;
    border-radius: 10px; top: 50%; left: 50%; transform: translate(-50%, -50%);
}
.login-form .title {padding-bottom: 0px;}
.login-form input[type="button"]{width: 100%; border-radius: 6px; margin: 0px 0px 10px; background: #6da252;}
.login-form .forgot-group{ text-align: center;}	
.login-form .forgot-group a{font-size: 16px;}

.file-status-wrap {max-width: 400px; padding: 70px 0px; margin: 0 auto;}
.file-status-wrap .form-group {width: 100%;}
.file-status-wrap .btn-group{ text-align: center; width: 100%;}
.file-status-wrap .title h4 {font-weight: 600; margin: 0px 0px 5px;}

.edit-profile-form {max-width: 720px; padding: 70px 0px; border-radius: 10px; margin: auto;}
.edit-profile-form .title {padding-bottom: 0px; text-align: left; position: relative;}
.edit-profile-form .title h4 {font-size: 22px; font-weight: 500;}
.edit-profile-form .form-row {
	margin-bottom: 60px; padding: 25px 35px 30px; border: 1px solid #707070; border-radius: 10px;
}
.edit-profile-form .form-row .btn-group {width: 100%; text-align: right; margin-top: 60px;}
.edit-profile-form .btn-group input[type="submit"]{border-radius: 6px; margin: 0px 0px 0px; background: #6da252;}

.modal.success-modal {
    width: 440px; height: 280px; background: #fff; border: 1px solid #b6b6b6;  border-radius: 10px;
    padding: 30px 60px; margin: auto; position: absolute; left: 0; right: 0; z-index: 9;
}
.modal.success-modal p {text-align: center; font-size: 20px;}
.modal.success-modal .close {
    font-size: 36px; font-weight: 500; position: absolute; top: 2px; right: 12px; line-height: 1;
}
.modal.success-modal .progressbar-container {
    height: 16px; width: 100%; border: 1px solid #d7e3ef; background: #dfebfb;
    border-radius: 10px; margin-bottom: 50px; margin-top: 70px;
}
.modal.success-modal .progressbar-progress {
    height: 100% !important; background-color: #62a9fa !important; border-radius: 10px;
}
.modal.success-modal button {margin: 0px 0px 0px; background: #6da252; width: 100%; border-radius: 6px;}
.modal.success-modal.email-modal {
    height: 120px;
    background: #42425c;
    border: 1px solid #42425c;
    z-index: 9;
    color: #fff;
    padding: 30px 20px;
}

.login-type-wrap {
    width: 420px;
    height: 360px;
    padding: 25px 35px 30px;
    border: 1px solid #707070;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 80px 30px;
    transform: translate(-50%, -50%);
}
.module-access {width: 100%; margin-top:20px;}
.module-access-row {display: flex; flex-wrap: wrap;}
.module-access-row > div {width: 25%; padding: 0px 15px 15px 0px;}
p.doc-file {margin: 0 0 5px;}
p.doc-file:last-child {margin: 0 0 0px;}
span.remove {
    color: #000;
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 700;
}
.account-update-wrap .form-row {margin-bottom: 20px;  align-items: center;}
.account-update-wrap .form-left, .form-right {width: 39%;}
.account-update-wrap .title { width: 18%;  text-align: right;}
.account-update-wrap .title h4 { font-size: 18px; font-weight: 600; margin: 0px 0px 15px 0px;}
.btn.Active, .btn.Inactive {
    margin: 0px 0px 0px; height: 38px; padding: 10px 25px; font-size: 14px; min-width: 120px;
}
.btn.Inactive {background: #e51c24;}
button.btn.delete-btn.Active {background: #259b23;}

@media(min-width:1400px){
	body{ font-size:26px;}
	.header {height: 75px;}
	.logo-box {height: 75px; width: 340px;}
	.logo-box a{font-size: 36px; line-height: 45px;}	
	.header-cnt {height: 75px; width: calc(100% - 340px);}
	.search-box .form-control {padding: 12px 22px 12px 62px; height: 60px; font-size: 28px;}
	.search-icon {top: 2px; left: 2px; padding: 12px 10px 2px;}
	.sidebar-menu {top: 75px; height: calc(100% - 70px); width: 340px;}	
	.menulist > li > a {padding: 20px 15px 20px 70px; font-size: 26px;}
	.menulist > li > a > i {width: 36px; height: 36px;}
    .header-cnt .icon{height:35px; width:35px;}
	.sidebar-menu .submenu.dropdown-menu {min-width: 225px;}
	.sidebar-menu .submenu.dropdown-menu.setting-dropdown{min-width: 340px;}
	.sidebar-menu .submenu a {font-size: 24px;}
    ul.user-menu {top: 75px; width: 185px;}    
    ul.user-menu li {padding: 8px 0px;}
    ul.user-menu li a {padding-left: 50px; font-size: 28px; }
    ul.user-menu .icon {top: 0px; }
	.content {margin-left: 340px; margin-top: 75px;} 
	
	/*--Form Wrapper--*/
	.form-wrapper {padding: 90px 120px;}
	.form-wrapper .form-control {padding: 12px 25px; height: 68px; font-size: 28px;}
	.title h4{ font-size:32px;}
	.action .delete-btn {height: 24px; width: 24px;}
	.login-form {width: 510px; height: 470px; padding: 40px 45px;}
	.form-wrapper.file-status-wrap {max-width: 500px;}
	
}	
@media(max-width:1400px){
	input[type="reset"],
	input[type="button"],
	input[type="submit"],
	.button,
	button,
	.btn {
		height: 42px; padding: 15px 25px; font-size: 16px;		
	}
	.letters-left .btn {min-width: 230px;}
	.header-right p {font-size: 14px;}
	.header-left h3 {font-size: 40px; padding: 10px 10px;}
	.legal-law-inner h4 {font-size: 16px;}
}	
@media(max-width:991px){

body{font-size: 14px;}
.btn {min-width: 140px; padding: 8px 24px; height: 42px;}
.header {height: 60px;}
.logo-box.mobi-close {display: none;}	
.mobi-logo{display:block;}	
.header-cnt {width: calc(100% - 0px);}

.sidebar-menu {top: 60px; height: calc(100% - 60px);}
.sidebar-menu .main-menu {padding: 15px 0px;}
.mobi-close.sidebar-menu {width: 75px;}
.mobi-close.sidebar-menu .menulist li a span {visibility: hidden; opacity: 0; transform: translateX(60px);}	
.mobi-close.sidebar-menu + .content{margin-left: 75px;}
.mobi-close.sidebar-menu .dropdown-toggle::after {display: none;}

.mobi-close.sidebar-menu .submenu {position: absolute !important; border: 4px; left: 30px !important; top: 0 !important; }
.mobi-close.sidebar-menu .submenu:before {
    content: ""; border: 10px solid #f8931e; border-color: transparent #f8931e transparent transparent;
    position: absolute; left: -20px; top: 7px;
}	
.mobi-close.menu-close.sidebar-menu {width: 240px;}
.mobi-close.menu-close.sidebar-menu .menulist li a span { visibility: visible; opacity: 1; transform: translateX(0px);}
.mobi-close.menu-close.sidebar-menu .submenu.show {position: static !important; left: 0 !important; top: 0 !important;}
.mobi-close.menu-close.sidebar-menu .dropdown-toggle::after {display: inline-block;}
.mobi-close.menu-close.sidebar-menu .submenu:before {border-color: transparent transparent transparent transparent;}
.dropdown-menu {font-size: 14px;}
.logo-box.active + .header-cnt {width: calc(100%);}
.menulist > li > a {font-size: 15px;}


}
@media(max-width:480px){
	
.header .menu-icon {display: inline-block; width: 30px;}
.header .menu-icon span {margin-bottom: 6px;}	
.menu-icon.active .icon_menu1 {transform: translateY(8px) rotate(45deg) scale(1);}
.mobi-close.sidebar-menu {width: 55px;}
.menulist > li > a > i {left: 12px; top: -2px; width: 24px; height: 24px; line-height: 24px; font-size: 22px;}
.menulist > li > a {padding: 12px 15px 12px 50px;}
.sidebar-menu .submenu.show {margin: 0px 10px 0px 40px;}
.mobi-close.sidebar-menu + .content {margin-left: 55px;}

}